import styles from '../assets/styles/pages/Landing.module.scss';

import { useEffect, useRef, useState } from 'react';

import Navigation from '../components/navigation/Navigation';

import { useNavigate } from 'react-router-dom';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Web3 from 'web3';

import contractABI from '../resources/contractABI.json'

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow, url, navigate }
  } = rest;

  return (
    //<div className="carousel-button-group">
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div className={styles['row-c2']}>
        <h4 style={{ alignSelf: 'left', textAlign: 'left', margin: '25px' }}>{rest.title}</h4>
        <small
          onClick={(e) => {
            e.preventDefault();
            rest.navigate(rest.url)
          }}
          style={{ borderBottom: '0.5px solid #000', cursor: 'pointer' }}>Explore →</small>
      </div>
      <div className={styles['row-c2']}>
        <svg
          style={{ margin: '5px', cursor: 'pointer' }}
          onClick={e => {
            e.preventDefault();
            previous()
          }}
          width="18px"
          height="18px" viewBox="-4.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <desc></desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -6679.000000)" fill="#000000"> <g id="icons" transform="translate(56.000000, 160.000000)"> <path d="M338.61,6539 L340,6537.594 L331.739,6528.987 L332.62,6528.069 L332.615,6528.074 L339.955,6520.427 L338.586,6519 C336.557,6521.113 330.893,6527.014 329,6528.987 C330.406,6530.453 329.035,6529.024 338.61,6539" id="arrow_left-[#334]"> </path> </g> </g> </g> </g></svg>
        <svg
          style={{ margin: '5px', cursor: 'pointer' }}
          onClick={e => {
            e.preventDefault();
            next()
          }}
          width="18px"
          height="18px" viewBox="-4.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <desc></desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-425.000000, -6679.000000)" fill="#000000"> <g id="icons" transform="translate(56.000000, 160.000000)"> <path d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519" id="arrow_right-[#333]"> </path> </g> </g> </g> </g></svg>
      </div>
    </div>
    // </div>
  );
};

export default function Landing() {
  const navigate = useNavigate();

  const [value, setValue] = useState(1);

  const [mintedCount, setMintedCount] = useState('x');

  const [minted, setMinted] = useState(false);

  const [connectedWallet, setConnectedWallet] = useState('');

  const [purchasePending, setPurchasePending] = useState(false);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    padding: '20px',
    backgroundColor: '#ffd5a4',
    borderRadius: '8px',
    width: '300px',
    justifyContent: 'space-between',
    margin: '18px'
  };

  const sliderStyle = {
    flex: 1,
    marginRight: '10px',
    color: '#000'
  };

  const valueStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333'
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [scrollTop, setScrollTop] = useState(0);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      getSupply()
    }
    //
  }, []);

  async function mint() {
    try {
      if (!purchasePending) {
        let web3 = new Web3(window.ethereum);

        setPurchasePending(true);

        let address = await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [
            {
              eth_accounts: {}
            }
          ]
        });

        let connectedWallet = address[0].caveats[0].value[0];

        let Contract = new web3.eth.Contract(contractABI, '0xc45E0b85F7098Ef3045126d80b515A4B4d554a26');

        // Check if the user has minted before
        let userBalance = await Contract.methods.balanceOf(connectedWallet).call();

        let amountToSend;

        // If it's their first mint and they're minting only one NFT, set the amountToSend to 0
        if (userBalance < 1 && value == 1) {
          amountToSend = '0';
        } else {
          const amount = (6.9 * Number(value)).toString();
          amountToSend = web3.utils.toWei(amount, "ether");
        }

        console.log("Value: ", value)

        Contract.methods.mint(value).send({ from: connectedWallet, value: amountToSend }).then(async response => {
          setPurchasePending(false);
          setMinted(true);
        }).catch(e => {
          setPurchasePending(false);
        })
      }
    } catch (e) {
      console.log('error @ mint: ', e)
      setPurchasePending(false);
    }
  };


  async function getSupply() {
    try {
      let web3 = new Web3(window.ethereum);
      let contract = new web3.eth.Contract(contractABI, '0xc45E0b85F7098Ef3045126d80b515A4B4d554a26');

      // Convert the result to a string
      let totalSupply = await contract.methods.supplyCount().call();
      let totalSupplyStr = Number(totalSupply.toString());

      setMintedCount(totalSupplyStr);

      return totalSupplyStr;
    } catch (e) {
      console.log('Error getting supply -> ', e);
    }
  }


  return (
    <> <div>
      <Navigation scrollTop={scrollTop} />
      <div className={styles.fixedWrapper}>

      </div>
      <main className={styles.sINCdcn} >
        <h1>Mint</h1>
        <div
          style={{
            width: '100%',
            padding: '25px',
            border: '2px solid #000',
            maxWidth: '600px',
            borderRadius: '5px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <h2 style={valueStyle}>{mintedCount} | 6969</h2>
          <h3>Mint is Live</h3>
          <button style={{
            background: '#ffd5a4',
            width: '250px',
            borderRadius: '5px',
            fontSize: '24px',
            cursor: 'pointer',
            padding: '12px'
          }}
            onClick={() => {
              mint()
            }}
          >
            Mint
          </button>
          <div style={containerStyle}>
            <input
              style={sliderStyle}
              type="range"
              min="1"
              max="20"
              value={value}
              onChange={handleChange}
            />
            <span style={valueStyle}>{value}</span>
          </div>
          <span style={valueStyle}>1 NFT free per wallet, 6.9 $BONE for more</span>
        </div>
      </main>
      <main id="c-2" className={styles.pOACn23} style={{ zIndex: '999' }}>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          autoPlay={false}
          shouldResetAutoplay={false}
          containerClass={styles['row-c3']}
          renderDotsOutside={true}
          arrows={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          renderButtonGroupOutside
          customButtonGroup={<ButtonGroup navigate={navigate} url={"/"} title={"The Shiberians"} />}
        >
          <div style={{ marginLeft: '0%' }} className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/5858.jpg'} />
          </div >
          <div className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6049.png'} />
          </div >
          <div className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6110.jpg'} />
          </div >
          <div className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6502.png'} />
          </div >
          <div className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6329.jpg'} />
          </div >
          <div className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6587.png'} />
          </div >
          <div className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6819.png'} />
          </div >
          <div style={{ border: 'none' }} className={styles.imgCard}>
            <img className={styles.imgCard} src={'/images/6851.png'} />
          </div >
        </Carousel>

      </main>
      <main id="about" className={styles.sINCdcn} style={{ zIndex: '999' }}>
        <div className={styles.bullet}>
          <h1>About & Utility</h1>
          <ul>
            <li>The Shibarians are one of the very first NFT collections to be deployed on the Shibarium blockchain. They aim to foster a community who is as excited as us about bringing innovation to our favorite blockchain!</li>
            <li>They consist of 6969 happy little Shiba Inu’s. Each has been programmatically generated with careful and delicate thought put into their traits & art.</li>
            <li>As the first project incubated by Shiba Labs, they offer several benefits to holders:</li>
            
              <li>Each NFT you hold will allow you to claim a portion of $SHLABS- The native ecosystem token for the Shiba Labs launchpad.</li>
              <li>Holders of 10 or more NFT’s are granted access to seed stage investment opportunities for both new coins & NFT’s incubated by Shiba Labs. (Allocation is proportional to NFT holdings, i.e hold 100 & be given a 10x higher allocation than a holder of 10 NFT’s).</li>
              <li>The Shibarians will be one of the cornerstones of the Shiba Labs ecosystem & will have value directly given to them through exclusive airdrops, WL spots & crossover utility from other projects incubated through Shiba Labs.</li>
            
          </ul>

        </div>
      </main>
      <main id="lore" className={styles.sINCdcn} style={{ zIndex: '999' }}>
        <div className={styles.about}>
          <h1>Lore</h1>
          <p>
            In the vast expanses of the digital cosmos, there was once a realm untouched by human civilization. This ethereal plane, shimmering with quantum energy, was known as Shibarium. As it grew in its vastness, life sparked in unexpected corners. From this unique digital realm, 6969 Shibarians emerged.

            Born from the very code of Shibarium, the Shibarians were majestic creatures, resembling the revered Shiba Inu from ancient Earth tales but with a mystical twist. Their fur shimmered with blockchain sequences, and their eyes held galaxies of encrypted secrets. They weren't just mere representations of beauty and enigma; they were the guardians of Shibarium's precious core.

            The first Shibarian, named Shiro, was said to have witnessed the birth of Shibarium. As the network expanded, so did the number of Shibarians, each imprinted with a unique code that held a piece of Shibarium's vast history. These creatures roamed the digital dunes, interacting with complex smart contracts and helping to maintain the balance and security of their home realm.

            As they navigated the endless terrains, the Shibarians discovered hidden vaults filled with forgotten tokens and relics from ancient crypto civilizations. Their mission became clear: to protect these vaults and ensure the continued prosperity of Shibarium. However, they weren't alone. There were those who sought to harness the power of these vaults for their own gains.

            Thus, the Shibarians became legends, coveted by many but truly owned by few. As NFTs, they transcended their digital realm, reaching the screens and portfolios of collectors across the universe. Each token wasn't just a piece of art; it was a badge of honor, a testament to the owner's allegiance to the Shibarians and their sacred quest.

            Today, as the tales of the Shibarians spread far and wide, the chosen guardians and their allies continue to stand strong, ensuring that the sanctity of Shibarium remains unbreached and its legends live on for years to come.


          </p>
        </div>
      </main>
      <footer id="footer" className={styles.ifW9Q}>
        <div className={styles.row}>
          <div className={styles.n9Acw1}>
            <div className={styles.pc8QWHCc}>
              <p className={styles.pAIcnue}><img style={{ margin: '2px' }} src="/images/logo.jpg" alt="logo" /> {"  "} &nbsp;The Shibarians</p>
              <p>(C) 2023. All Rights Reserved.</p>
            </div>
          </div>
          <div className={styles.rowFooter}>

          </div>
        </div>
      </footer>
    </div></>
  );
}