import styles from './Navigation.module.scss';
import { Link, useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';
import clsx from 'clsx';

export default function Navigation({ scrollTop }) {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const navigate = useNavigate();
  return (
    <nav className={clsx([styles.navigation, open && styles.navigationOpen])}>
      {/*<div 
        style={{ 
          width: '100%', 
          display: 'flex',
          flexDirection: 'column',
          margin: '0',
          background: '#50913f',
          alignItems: 'center',
          textAlign: 'center',
          cursor: 'pointer'
        }}
      >
        <h1 style={{ fontSize: '20px' }}>Peepo Depot</h1>
      </div>*/}
      <div className={styles.container}>
        {/*<button className={styles.menuButton} onClick={() => setOpen(!open)}>
          {!open && <MenuIcon />}
          {open && <LargeCloseIcon />}
        </button>*/}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'space-between',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <small></small>
            <div
              className={styles.logo}
            >
              {<img
                onClick={e => {
                  e.preventDefault();
                  navigate('/');
                }}
                style={{ margin: '55px', cursor: 'pointer', width: '75px', height: '75px', transition: '.25s ease-in-out' }} 
                    
                  src="/images/logo.jpg" alt="logo" />}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg style={{ display: 'none' }} height="1em" width="1.135em" viewBox="0 0 22.4 19.732" class="css-qwlnpf e1cpiwc53"><path d="m16.148 0a6.251 6.251 0 0 1 4.42 10.672l-8.84 8.84a.75.75 0 0 1 -1.061 0l-8.839-8.84a6.251 6.251 0 0 1 8.84-8.84l.53.53.53-.53a6.209 6.209 0 0 1 4.42-1.832zm-4.95 17.921 8.31-8.31a4.751 4.751 0 1 0 -6.72-6.719l-1.06 1.06a.75.75 0 0 1 -1.061 0l-1.059-1.06a4.752 4.752 0 0 0 -6.72 6.72z"></path></svg>
            </div>
          </div>
          {<ul>
            <li>
              <Link
              onClick={e => {
                e.preventDefault();
                const element = document.getElementById("about");
                element.scrollIntoView();
              }}
                to={'/'}
              >About & Utility</Link>
            </li>
            <li>
              <Link                 onClick={e => {
                  e.preventDefault();
                  window.open('https://twitter.com/ShibariansNFT', '_target');
                }} to={'/'}>| Twitter</Link>
            </li>
            <li>
              <Link  onClick={e => {
                  e.preventDefault();
                  window.open('https://t.me/shibalabsentry', '_target');
                }}  to={'/'}>| Telegram</Link>
            </li>

            <li>
              <Link onClick={e => {
                e.preventDefault();
                const element = document.getElementById("lore");
                element.scrollIntoView();
              }} to={'/'}>| Lore</Link>
            </li>
          </ul>}
        </div>
      </div>
    </nav>
  );
}